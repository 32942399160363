body {
  -ms-overflow-style: none;
  overscroll-behavior-y: none;
}

::-webkit-scrollbar {
  display: none;
}

.blank {
  background-color: white;
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  justify-content: center;
}

* :focus {
  outline: none;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(42, 47, 61, 0.32);
  opacity: 1; /* Firefox */
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(42, 47, 61, 0.32);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(42, 47, 61, 0.32);
  opacity: 1; /* Firefox */
}
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(42, 47, 61, 0.32);
}

.App {
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
}
