.background {
    background-color: white;
    width: 100vw;
    max-width: 414px;
    min-width: 320px;
    height: fit-content;
    font-family: Helvetica Neue;
}

.content {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    margin: 0 24px;
    width: calc(100% - 48px);

    color: rgba(28, 31, 41, 0.96);
    text-align: center;
}

.title {
    margin-top: 80px;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 20px;
}

.sub_title {
    font-weight: 400;
    font-size: 14px;
}

.logo {
    aspect-ratio: auto;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 17px;
}