.background {
    background-color: white;
    width: 100vw;
    max-width: 414px;
    min-width: 320px;
    height: fit-content;
    font-family: Helvetica Neue;
}

.content {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    margin: 0 24px;
    width: calc(100% - 48px);

    color: rgba(28, 31, 41, 0.96);
    text-align: center;
}

.top {
    margin-top: 40px;
    margin-bottom: 32px;
}

.title {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: rgba(28, 31, 41, 0.96);
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.inline_title {
    display: inline;
}

.highlight {
    font-weight: 700;
    margin: 0 8px;
}

.invite {
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid rgba(42, 47, 61, 0.08);
    border-radius: 8px;
    overflow: hidden;
}

.gift_img {
    aspect-ratio: auto;
    width: inherit;
    object-fit:contain;
}

.message_area {
    margin: 17px 16px 24px 16px ;
}

.coffeechat_invite {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 16px;
}

.message_sub_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.02em;
}

.message_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
}

.personal_message {
    padding: 16px;
    width: calc(100% - 32px);
    height: fit-content;
    background: #FAFAFA;
    border-radius: 8px;
    margin-top: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: rgba(42, 47, 61, 0.64);
}

.button_space {
    width: 100%;
    height: 88px;
}
.input_deadline {
    position: fixed;
    bottom: 85px;
    min-width: 184px;
    width: fit-content;
    background: #FFFFFF;
    border: 1px solid rgba(42, 47, 61, 0.08);
    border-radius: 100px;
    cursor: pointer;

    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: rgba(42, 47, 61, 0.64);

    display: flex;
    padding: 8px 24px;
    margin-bottom: 8px;
}

.input_date {
    font-weight: 700;
    padding: 0 3px;
}

.friend_invite_btn {
    position: fixed;
    bottom: 24px;
    width: calc(100% - 48px);
    max-width: 366px;
    min-width: 272px;
    height: 56px;
    border: none;
    border-radius: 8px;
    background: rgba(28, 31, 41, 0.96);
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    box-shadow: 0px 2px 16px rgba(108, 163, 245, 0.48);
}