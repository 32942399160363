.viewport {
  width: 100vw;
  height: 100vh;
  /* overflow: scroll; */
  max-width: 414px;
  min-width: 320px;
}

.background {
  width: inherit;
  height: fit-content;
  max-width: 414px;
  min-width: 320px;
  font-family: Helvetica Neue;
  background: linear-gradient(241.41deg, #770ffc 28.29%, #008ef2 126.36%),
    #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.upper_wrapper {
  height: calc(100% - 45px);
  width: calc(100% - 40px);
  margin: 16px 20px 0 20px;
}

.side_padding {
  height: inherit;
  width: calc(100% - 40px);
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  width: 100%;
  height: 192px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.72);
  text-align: left;
}

.personal_info {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  border-top: 1px solid rgba(42, 47, 61, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.arrow_icon {
  font-size: 17px;
  padding-left: 17px;
  padding-top: 1px;
}

.top_title {
  line-height: 160%;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 4px;
}

.field_info {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.field_title {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.button_wrapper {
  min-height: 80px;
  margin-top: 14px;
}

.complete {
  width: 100%;
  min-height: 56px;
  border: none;
  border-radius: 8px;
  background: rgba(28, 31, 41, 0.96);
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.question {
  font-weight: 700;
  font-size: 13px;
  color: rgba(42, 47, 61, 0.64);
}
.question_title {
  margin-top: 24px;
}

.question_sub_title {
  margin-top: 20px;
  margin-bottom: 5px;
}

.essintial {
  font-size: 12px;
  color: #5e67eb;
}

.company_name {
  margin-bottom: 34px !important;
}

.bottom_margin {
  margin-bottom: 52px;
}

.drawer_btn {
  height: 56px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(42, 47, 61, 0.08);
  margin-top: 10px;
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  color: rgba(42, 47, 61, 0.32);

  padding: 1px 6px;
}

.drawer_text {
  margin-left: 8px;
}

.drawer_chosen {
  color: rgba(28, 31, 41, 0.96);
}

.drawer_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow_icon {
  font-size: 30px;
  color: rgba(28, 31, 41, 0.96);
}

.question_input {
  height: 56px;
  width: calc(100% - 2px);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  margin-top: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;

  text-indent: 15px;

  font-family: "Helvetica Neue";
  font-style: normal;
}

.question_input:focus {
  border: 1px solid #5e67eb;
}

.question_terms {
  -webkit-overflow-scrolling: touch;
  white-space: pre-wrap;

  overflow-y: scroll;
  height: 120px;
  width: calc(100% - 2px);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.16);
  border-radius: 2px;

  margin-top: 10px;

  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.02em;

  text-indent: 15px;

  font-family: "Helvetica Neue";
  font-style: normal;
  color: rgba(42, 47, 61, 0.64);

  scrollbar-width: 10px;
  scrollbar-color: #5e67eb #ffffff;
}
.question_terms::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none;
  width: 4px;
}
.question_terms::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgba(42, 47, 61, 0.32);
}

.question_terms_wrap {
  padding: 16px;
}

.incomplete {
  opacity: 0.3;
}

.choice_set {
  display: flex;
}

.choice_btn {
  flex: 1;
  color: rgba(28, 31, 41, 0.96);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  height: 60px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-right: 3px;
}

.selected {
  background: #ebedff;
  border: 1px solid #5e67eb;
}

.end_btn {
  margin-right: 0;
}

.btn_text {
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 13px;
}

.adjust_height {
  height: fit-content;
  padding: 20px 0;
}

.emoji {
  aspect-ratio: auto;
  width: 32px;
  font-size: 32px;
  padding: 7px 0;
}

.bottom_split {
  border-bottom: 1px solid rgba(42, 47, 61, 0.08);
  padding-bottom: 40px;
}

.info_text {
  height: fit-content;

  background: #fafafa;
  border-radius: 4px;

  font-weight: 400;
  font-size: 11px;
  color: rgba(42, 47, 61, 0.64);

  margin-top: 40px;
  padding: 10px;
}

.error {
  color: #f66563;
}

.error_border {
  border: 1px solid #f66563;
}

.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 16px;
  top: 26px;
  content: attr(data-placeholder);
  pointer-events: none;
  color: rgba(42, 47, 61, 0.32);
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
}
