.background {
    background-color: white;
    width: 100%;
    min-width: 320px;
    max-width: 414px;
    height: 100vh;
    font-family: Helvetica Neue;
}

.content {
    display: flex;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    padding: 0 24px;
}

.center {
    padding-top: 139px;
}

.bottom {
    margin-top: 24px;
    width: 100%;
}

.logo {
    width: 160px;
    height: 160px;
    margin: 24px 0;
}

.title {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: center;
}

.sub_title {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    
    text-align: center;
    letter-spacing: -0.03em;
    
    color: rgba(42, 47, 61, 0.64);
}

.enter_btn {
    width: 100%;
    height: 56px;
    background: rgba(28, 31, 41, 0.96);
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: white;
    cursor: pointer;
}