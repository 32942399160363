.background {
    background-color: white;
    width: 100vw;
    min-width: 320px;
    max-width: 414px;
    height: fit-content;
    min-height: 100vh;
    font-family: Helvetica Neue;
}

.content {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    margin: 0 24px;
}

.back_arrow {
    margin-top: 16px;
    width: 100%;
}

.content_info {
    width: 100%;
}

.title {
    width: 100%;
    margin-top: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.03em;
    color: rgba(28, 31, 41, 0.96);
    padding-bottom: 32px;
    border-bottom: 2px solid rgba(42, 47, 61, 0.16);
}

.transfer_info {
    width: 100%;
    padding: 16px 0;

    font-weight: 700;
    font-size: 13px;
    line-height: 160%;    
    letter-spacing: -0.02em;
    color: rgba(28, 31, 41, 0.96);
}

.receipent_wrap {
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid rgba(42, 47, 61, 0.16);
    border-bottom: 1px solid rgba(42, 47, 61, 0.16);

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: rgba(28, 31, 41, 0.96);

    display: flex;
}

.receipent {
    margin-right: 16px;
}

.name {
    font-weight: 700;
}

.company_info {
    margin: 24px 0;
    height: fit-content;
    background: linear-gradient(241.41deg, #770FFC 28.29%, #008EF2 126.36%), #FAFAFA;
    border-radius: 8px;

    padding: 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.02em;

    color: #FFFFFF;
}

.text_wrap {
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
}

.company_name {
    font-weight: 700;
}

.company_text {
}

.message_box {
    height: fit-content;
    margin-bottom: 88px;
    background: #FAFAFA;
    border-radius: 8px;
    padding: 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: rgba(28, 31, 41, 0.8);
}

.message_header {
    font-weight: 700;
    font-size: 13px;
    color: rgba(28, 31, 41, 0.96);
    margin-bottom: 16px;
}

.bottom_button {
    position: fixed;
    bottom: 0;
    width: 100vw;
    min-width: 320px;
    max-width: 414px;
    height: 104px;
}

.gradation_background {
    height: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.solid_background {
    height: 88px;
    background: #FFFFFF;
    margin: 0 24px;
}

.confirm_button {
    height: 56px;
    width: 100%;
    background: rgba(28, 31, 41, 0.96);
    border: none;
    border-radius: 8px;
    margin: 8px 0 24px 0;

    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}