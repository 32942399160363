.background {
    background-color: white;
    width: 100%;
    max-width: 414px;
    min-width: 320px;
    height: fit-content;
    font-family: Helvetica Neue;
}

.gift_img {
    aspect-ratio: auto;
    width: inherit;
    object-fit:contain;
}

.content {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    padding: 0 24px;
    width: inherit;
}

.title {
    margin-top: 87px;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.03em;
    color: rgba(28, 31, 41, 0.96);
}

.message_wrap {
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid rgba(42, 47, 61, 0.08);
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;

}

.message_area {
    margin: 17px 16px 24px 16px ;
}

.message_content {
    padding: 8px 21px;
    width: calc(100% - 42px);
    height: 38px;
    background: #FAFAFA;
    border-radius: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(42, 47, 61, 0.64);
}

.message_title {
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: rgba(28, 31, 41, 0.96);
    text-align: center;
    margin-bottom: 16px;
}

.button_space {
    width: 100%;
    height: 88px;
}

.send_invitation {
    margin: 24px 0;
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.02em;

    background: rgba(28, 31, 41, 0.96);
    color: #FFFFFF;
    box-shadow: none;
}