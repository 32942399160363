.title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: rgba(28, 31, 41, 0.96);
  margin: 32px 24px 8px 24px;

  letter-spacing: -0.02em;
  line-height: 160%;
}

.desc {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: rgba(42, 47, 61, 0.64);
  margin: 0 24px;

  letter-spacing: -0.02em;
  line-height: 160%;
}

.btn {
  background: linear-gradient(135deg, #7e6cf5 0%, #6ca3f5 100%);
  box-shadow: 0px 2px 16px rgba(108, 163, 245, 0.48);
  border-radius: 8px;
  border: none;

  font-weight: 800;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  height: 56px;
  margin: 32px 24px 24px 24px;

  cursor: pointer;

  flex: 1;
}

.btn_wrap {
  display: flex;
}

.double_btn_left {
  margin-right: 8px;
  background: #eef3fb;
  color: rgba(28, 31, 41, 0.96);
  box-shadow: none;
}

.double_btn_right {
  margin-left: 8px;
}
