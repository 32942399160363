.background {
  background-color: white;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: fit-content;
  font-family: Helvetica Neue;
}

.content {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  margin: 0 24px;
}
