.background {
  background-color: white;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: fit-content;
  font-family: Helvetica Neue;
}

.content {
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 100vh;
  flex-direction: column;
  margin: 0 24px;
}

.back_arrow {
  margin-top: 16px;
  width: 100%;
}

.message_wrap {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
}

.gift_img {
  aspect-ratio: auto;
  width: 100%;
  object-fit: contain;
}

.message_area {
  padding: 17px 16px 24px 16px;
  width: calc(100% - 32px);
}

.message_content {
  padding: 16px;
  height: 110px;
  background: #fafafa;
  border-radius: 8px;
  margin-top: 8px;
}

.message_input {
  background: #fafafa;
  border: none;
  width: 100%;
  height: 88px;

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.message_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.message_length {
  width: 100%;
  height: 19px;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  text-align: right;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.recipient_info {
  width: 100%;
  height: fit-content;
  margin-top: 58px;
}

.recipient_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.8);
  text-align: left;
}

.channelSelection {
  height: 48px;
  background-color: #fafafa;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(28, 31, 41, 0.96);
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 4px;
}

.menu_btn {
  height: calc(100% - 4px);
  width: calc(100% / 2 - 2px);
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
  border-radius: 8px;
  background: #000000;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 0;
}

.active_btn {
  color: #ffffff;
  background: #000000;
  font-weight: 700;
}

.unactive_btn {
  background-color: #fafafa;
  color: rgba(28, 31, 41, 0.96);
}

.info_title {
  font-weight: 700;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(42, 47, 61, 0.64);
  margin-bottom: 5px;
}

.phone_title {
  margin-top: 16px;
}

.info_input {
  height: 48px;
  width: calc(100% - 2px);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  padding: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;

  text-indent: 15px;

  font-family: 'Helvetica Neue';
  font-style: normal;
}

.info_input:focus {
  border: 1px solid #5e67eb;
}

.send_invitation {
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;

  margin-top: 40px;
  margin-bottom: 24px;
}

.kakao_icon {
  height: 22.05px;
  aspect-ratio: auto;
  margin-right: 10px;
  margin-top: 1px;
}

.div_wrap {
  display: flex;
  justify-content: center;
}

.send_kakao {
  color: #381e1f;
  background: #ffdc00;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
}

.send_text {
  background: rgba(28, 31, 41, 0.96);
  color: #ffffff;
  box-shadow: none;
}

.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 16px;
  top: 12px;
  content: attr(data-placeholder);
  pointer-events: none;
  color: rgba(42, 47, 61, 0.32);
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
}
