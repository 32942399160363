.background {
  background-color: white;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: 100vh;
  font-family: Helvetica Neue;
}

.content {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: scroll;
  flex-direction: column;
  text-align: center;
  width: inherit;
}

.center {
  height: 100vh;
  padding: 0 20px;
}

.bottom {
  width: 100%;
}

.recommend_list {
  margin: 24px 20px 16px 20px;
  height: fit-content;
}

.center_btn {
  display: flex;
  justify-content: center;
}

.content_space_between {
  justify-content: space-between;
}

.mobile_background {
  height: 410px;
  width: 100%;
  background: linear-gradient(241.41deg, #770ffc 28.29%, #008ef2 126.36%),
    #ffffff;
}

.button_wrap {
  margin-top: 24px;
  width: 178px;
  height: 30px;
  background-color: #fafafa;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: rgba(28, 31, 41, 0.96);
  position: relative;
}

.menu_btn {
  width: 90px;
  height: 32px;
  font-size: 12px;
  border-radius: 100px;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.active_btn {
  left: 1px;
  color: #ffffff;
  background: #000000;
  font-weight: 700;
}

.unactive_btn {
  right: 1px;
  background-color: transparent;
  color: rgba(28, 31, 41, 0.96);
  font-weight: 400;
}

.name {
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-align: left;
  margin-bottom: 32px;
  margin-top: 24px;
}

.dashboard {
  width: calc(100% - 32px);
  height: 130px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  border: 1px solid #ffffff;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 1;
  display: flex;
  height: 75px;
}

.block {
  color: #ffffff;
  line-height: 160%;
  flex: 1;
}

.block_name {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.02em;
}

.block_underline {
  text-decoration: underline;
}

.block_value {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.03em;
}

.border_bottom {
  border-bottom: 1px solid #ffffff;
}

.border_right {
  border-right: 1px solid #ffffff;
}

.padding_top {
  padding-top: 10px;
}

.padding_bottom {
  padding-bottom: 10px;
}

.frined_invite_btn {
  width: 100%;
  height: 56px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: rgba(28, 31, 41, 0.96);
  cursor: pointer;

  margin-top: 16px;
  padding: 0;
}

.invite_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 17px 16px;
}

.icon_wrap {
  display: flex;
}

.given_invitation {
  font-size: 14px;
}

.arrow_icon {
  font-size: 24px;
  padding-left: 15px;
  padding-top: 1px;
}

.see_you_first {
  font-weight: 400;
}

.card {
  border: 1px solid rgba(42, 47, 61, 0.08);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  text-align: left;
}

.status {
  font-weight: 700;
  font-size: 11px;
  color: #5e67eb;
  width: 60px;
}

.detail {
  width: calc(100% - 60px);
}

.card_name {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.8);
}

.detail_info {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: rgba(42, 47, 61, 0.64);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.write_comment {
  font-weight: 700;
  font-size: 13px;
  color: #5e67eb;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #5e67eb;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0 2px 0;
  cursor: pointer;
}

.info_text {
  font-weight: 400;
  font-size: 11px;
  line-height: 160%;
  color: rgba(42, 47, 61, 0.64);
  background: #fafafa;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding: 4px 0;
  margin-top: 8px;
}

.dday {
  font-weight: 400;
  font-size: 11px;
  color: rgba(42, 47, 61, 0.64);
}

.name_dday_wrap {
  display: flex;
  justify-content: space-between;
}

.recommend_done {
  color: rgba(28, 31, 41, 0.96);
}

.recommend_hold {
  cursor: pointer;
}
.recommend_hold:hover {
  text-decoration: underline;
}

.bottom_info {
  font-weight: 400;
  font-size: 11px;
  color: rgba(42, 47, 61, 0.64);
  padding: 12px 0;
  background: #fafafa;
  width: 100%;
  margin: 16px 0;
  margin-top: 24px;
  margin-bottom: 80px;
}
