.background {
  background-color: white;
  width: 100vw;
  max-width: 414px;
  min-width: 320px;
  height: fit-content;
  font-family: Helvetica Neue;
}

.content {
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 100vh;
  flex-direction: column;
  margin: 0 24px;
  width: calc(100% - 48px);

  color: rgba(28, 31, 41, 0.96);
  text-align: center;
}

.image {
  width: 100%;
}
