.background {
    background-color: white;
    width: 100vw;
    min-width: 320px;
    max-width: 414px;
    height: 100vh;
    font-family: Helvetica Neue;
}

.content {
    padding: 0 24px;
    height: fit-content;
}

.back_arrow {
    margin-top: 16px;
}

.desc {
    margin-top: 16px;
    margin-bottom: 32px;

}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
}

.title_desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: rgba(42, 47, 61, 0.64);
    margin-top: 4px;
}


.input_wrap {
    margin-bottom: 12px;
}

.input_name {
    font-size: 13px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: rgba(42, 47, 61, 0.64);
    margin-bottom: 12px;
}

.btm_desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(42, 47, 61, 0.64);
}

.input_btn {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 32px;
}

.active {
    border: solid 1px #5E67EB;
    color: rgba(28, 31, 41, 0.96);
}

.disabled {
    border: solid 1px #5e67eb6f;
    color: rgba(42, 47, 61, 0.16);
}


.input_error {
    height: 19px;
    width: 100%;

    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-top: 8px;
}

.error {
    color: #F66563;
}


.visible_hidden {
    visibility: hidden;
}

.input::after {
    border-bottom: 1px solid #5E67EB !important;
}

.input:hover {
    border-bottom: none !important;
}