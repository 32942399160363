.background {
  background-color: white;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: fit-content;
  font-family: Helvetica Neue;
}

.upper_content {
  display: flex;
  align-items: center;
  height: 500px;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 0 24px;
}

.content {
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 0 24px;
  background: linear-gradient(
    180deg,
    rgba(119, 15, 252, 0.05) 0%,
    rgba(119, 15, 252, 0) 29.98%
  );
}

.inner_content {
  width: 100%;
}

.content_space_between {
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.button_wrap {
  margin-top: 24px;
  width: 178px;
  height: 30px;
  background-color: #fafafa;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: rgba(28, 31, 41, 0.96);
  position: relative;
}

.menu_btn {
  width: 90px;
  height: 32px;
  font-size: 12px;
  border-radius: 100px;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.active_btn {
  right: 1px;
  color: #ffffff;
  background: #000000;
  font-weight: 700;
}

.unactive_btn {
  left: 1px;
  background-color: transparent;
  color: rgba(28, 31, 41, 0.96);
  font-weight: 400;
}

.logo {
  aspect-ratio: auto;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.sub_title {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(42, 47, 61, 0.64);
  margin-bottom: 4px;
}

.main_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: rgba(28, 31, 41, 0.96);
}

.invite_info {
  width: 100%;
  height: 26px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);

  border: 1px solid #ebedff;
  border-radius: 8px;

  padding: 16px 0;
  display: flex;
  justify-content: center;
  line-height: 1.5;
}

.invite_bold {
  font-weight: 800;
  padding-left: 4px;
  line-height: 1.5;
}

.bottom {
  padding-bottom: 30px;
}

.check_invite {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  width: fit-content;
  background-color: transparent;
  padding: 18px 20px;
  height: 22px;
  border: 1px solid #770ffc;
  background-color: #ffffff;
  border-radius: 20px;
  line-height: 0;
  position: fixed;
  bottom: 104px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  color: rgba(28, 31, 41, 0.96);
}

.info_check_btn {
  width: 100%;
  height: 56px;
  background: transparent;
  border: 1px solid rgba(42, 47, 61, 0.48);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 3.5;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  cursor: pointer;
  align-items: center;
  padding: 0;
}

.txt_section {
  display: flex;
}

.new {
  color: rgba(28, 31, 41, 0.96);
  font-size: 14px;
  padding-left: 16px;
}

.check_txt {
  padding-left: 8px;
  font-size: 14px;
  color: #5e67eb;
}

.arrow_icon {
  font-size: 10px;
  padding-right: 14px;
  color: black;
}

.people_list {
  width: 100%;
  height: fit-content;
  margin-top: 16px;
  margin-bottom: 158px;

  border: 1px solid rgba(42, 47, 61, 0.08);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.people_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
  padding: 24px 0 16px 0;
  border-bottom: 1px solid rgba(42, 47, 61, 0.08);
}

.people_scroll {

  /* overflow: scroll; */
  /* max-height: calc(100vh - 420px); */
}

.people_ul {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: left;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.people_li {
  margin: 8px 0;
}

ul li::marker {
  font-size: 11px;
}

.div_wrap {
  display: flex;
}

.job_year_wrap {
  display: inline;
}

.job {
  padding: 0 8px;
}

.year {
  font-weight: 700;
}

.friend_invite_btn {
  height: 56px;
  width: 100%;

  background: linear-gradient(241.41deg, #770ffc 28.29%, #008ef2 126.36%),
    rgba(28, 31, 41, 0.96);
  box-shadow: 0px 2px 16px rgba(108, 163, 245, 0.48);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding: 15px 0;
  margin-top: 8px;
  border: none;

  cursor: pointer;
}

.top_position {
  margin-top: 32px;
}

.invite_count {
  padding-left: 8px;
  font-weight: 700;
}

.no_invitation_disabled {
  opacity: 0.3;
}

.bottom_button {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: 104px;
}

.gradation_background {
  height: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.solid_background {
  height: 88px;
  background: #ffffff;
  padding: 0 24px;
}

.link_tag {
  text-decoration: none;
}
