
.title {
    width: 100%;
    max-width: 375px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: rgba(28, 31, 41, 0.96);
    margin-top: 27px;
    margin-bottom: 11px;
    font-family: 'Helvetica Neue';
}

.btm_margin {
    width: 100%;
    max-width: 375px;
    height: 20px;
}

.option_list {
    max-width: 375px;
    width: 100%;
    padding: 15px 24px;
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px 24px !important;
}

.option_list_txt {
    color: #1c1f29f5;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.02em;
}

.scroll_cover {
    overflow: hidden;    
    height: 100%;
}

.scroll_content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 100px);
}