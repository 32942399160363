.viewport {
  width: 100vw;
  height: 100vh;
  /* overflow: scroll; */
  max-width: 414px;
  min-width: 320px;
}

.background {
  width: inherit;
  height: fit-content;
  max-width: 414px;
  min-width: 320px;
  font-family: Helvetica Neue;
  background: linear-gradient(241.41deg, #770ffc 28.29%, #008ef2 126.36%),
    #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.side_padding {
  height: calc(100% - 9px);
  width: calc(100% - 48px);
  margin: 16px 24px 0 24px;
}

.info {
  width: 100%;
  height: 176px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.72);
  text-align: left;
}

.survey {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  border-top: 1px solid rgba(42, 47, 61, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: scroll;
}

.arrow_icon {
  font-size: 17px;
  padding-left: 17px;
  padding-top: 1px;
}

.name {
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 4px;
}

.job_info {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button_space {
  width: 100%;
  height: 98px;
}

.complete {
  height: 56px;
  width: 100%;

  border: none;
  border-radius: 8px;
  background: rgba(28, 31, 41, 0.96);
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;

  padding: 15px 0;
  margin-top: 8px;
}

.question {
  font-weight: 700;
  font-size: 13px;
  color: rgba(42, 47, 61, 0.64);
}

.bottom_split {
  border-bottom: 1px solid rgba(42, 47, 61, 0.08);
  padding-bottom: 40px;
}

.question_info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.question_title {
  margin-top: 24px;
  line-height: 160%;
}

.my_relation {
  margin-top: 40px;
}

.question_sub_title {
  margin-top: 24px;
  margin-bottom: 5px;
}

.essintial {
  font-size: 12px;
  line-height: 160%;
  color: #5e67eb;
}

.question_desc {
  font-weight: 400;
  line-height: 160%;
}

.choice_set {
  display: flex;
}

.choice_btn {
  flex: 1;
  color: rgba(28, 31, 41, 0.96);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  height: 125px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}

.selected_fit {
  background: #ebedff;
  border: 1px solid #5e67eb;
}

.center_btn {
  margin-left: 3px;
  margin-right: 3px;
}

.emoji {
  aspect-ratio: auto;
  width: 32px;
  font-size: 32px;
  padding: 7px 0;
}

.btn_text {
  margin-bottom: 3px;
}

.drawer_btn {
  height: 56px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(42, 47, 61, 0.08);
  margin-top: 10px;
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  color: rgba(42, 47, 61, 0.32);

  padding: 1px 6px;
}

.drawer_text {
  margin-left: 10px;
}

.drawer_chosen {
  color: rgba(28, 31, 41, 0.96);
}

.drawer_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow_icon {
  font-size: 30px;
  color: rgba(28, 31, 41, 0.96);
}

.relationship_input {
  height: 56px;
  width: calc(100% - 2px);
  background: #ffffff;
  border: 1px solid rgba(42, 47, 61, 0.08);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0;
  cursor: pointer;
  margin-top: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;

  text-indent: 15px;
}

.relationship_input:focus {
  border: 1px solid #5e67eb;
}

.text_background {
  padding: 16px;
  width: calc(100% - 32px);
  height: 210px;
  background: #fafafa;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
}

.message_input {
  background: #fafafa;
  border: none;
  width: 100%;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
  flex: 1;
}

.message_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.message_length {
  width: 100%;
  height: 19px;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  text-align: right;
  letter-spacing: -0.02em;
  color: rgba(28, 31, 41, 0.96);
}

.slider {
  width: "inherit";
  max-width: 375px;
  height: 20%;
  background-color: "red";
  position: "fixed";
  bottom: 0;
}

.error {
  color: #f66563;
}

.border_error {
  border-color: #f66563;
}

.mesesage_error {
  border: 1px solid #f66563;
}

.bottom_button {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-width: 320px;
  max-width: 414px;
  height: 104px;
}

.gradation_background {
  height: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.solid_background {
  height: 88px;
  background: #ffffff;
  padding: 0 24px;
}
